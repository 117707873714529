import { ReactNode, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Overlay } from './Overlay';
import { expectNever } from '../../utils';

import styles from './Modal.module.css';

type Props = {
    children: ReactNode;
    shown?: boolean;
    onClose?: VoidFunction;
}

type TransitionState = 'shown' | 'hiding' | 'hidden' | 'showing';

export const Modal = ({ children, shown, onClose }: Props) => {
    const [ isOverlayShown, setIsOverlayShown ] = useState(shown);
    const [ state, setState ] = useState<TransitionState>(shown ? 'shown' : 'hidden');

    const contentRef = useRef(children);
    if (shown) {
        contentRef.current = children;
    }

    useEffect(() => {
        if (shown) {
            setIsOverlayShown(true);
        }
    }, [shown])

    useEffect(() => setState(oldState => {
        switch (oldState) {
            case 'shown':
            case 'showing':
                return shown ? oldState : 'hiding';

            case 'hidden':
            case 'hiding':
                return shown ? 'showing' : oldState;

            default:
                return expectNever(oldState);
        }
    }), [shown])

    const shouldShownOverlay = shown || isOverlayShown;

    return (
        <>
            { shouldShownOverlay && <Overlay shown={shown} onHide={() => setIsOverlayShown(false)} onClick={onClose} /> }

            <div className={classNames(styles.modal, state === 'hiding' && styles.hiding, state === 'hidden' && styles.hidden)} onTransitionEnd={() => setState(oldState => {
                switch (oldState) {
                    case 'hidden':
                    case 'shown':
                        return oldState;

                    case 'showing':
                        return 'shown';

                    case 'hiding':
                        return 'hidden';

                    default:
                        return expectNever(oldState);
                }
            })}>
                {onClose && (
                    <div className={styles.header}>
                        <a className={styles.close} onClick={onClose}>Закрыть</a>
                    </div>
                )}
                <div className={styles.body}>{contentRef.current}</div>
            </div>
        </>
    );
}