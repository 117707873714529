import styles from './IconButton.module.css';

type Props = {
    children: string;
    disabled?: boolean;
    onClick?: VoidFunction;
}

export const IconButton = ({ children, disabled, onClick }: Props) => (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
        {children}
    </button>
);
