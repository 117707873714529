import { ReactNode } from 'react';

import styles from './Link.module.css';

type Props = {
    children: ReactNode;
    onClick?: VoidFunction;
}

export const Link = ({ children, onClick }: Props) => (
    <a className={styles.link} onClick={onClick}>{children}</a>
);
