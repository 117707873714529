export const nouns = [
  "аббат",
  "абзац",
  "аборт",
  "абрек",
  "абрис",
  "аванс",
  "авось",
  "аврал",
  "автол",
  "автор",
  "агава",
  "агент",
  "агнец",
  "адепт",
  "адрес",
  "азарт",
  "аймак",
  "актер",
  "актив",
  "акула",
  "акциз",
  "акция",
  "алиби",
  "алкаш",
  "аллах",
  "аллея",
  "аллюр",
  "алмаз",
  "алтын",
  "алыча",
  "альфа",
  "амбар",
  "амвон",
  "амеба",
  "аминь",
  "ампер",
  "ампир",
  "ангар",
  "ангел",
  "анонс",
  "анфас",
  "анчар",
  "аорта",
  "апноэ",
  "апорт",
  "апчхи",
  "арбуз",
  "аргон",
  "ареал",
  "арена",
  "арест",
  "аркан",
  "армия",
  "армяк",
  "архар",
  "архив",
  "аршин",
  "аскет",
  "аспид",
  "астма",
  "астра",
  "атака",
  "атлас",
  "атлет",
  "атолл",
  "афера",
  "афиша",
  "бабка",
  "багаж",
  "багет",
  "багор",
  "бадья",
  "базар",
  "базис",
  "байка",
  "бакен",
  "балда",
  "балет",
  "балка",
  "балок",
  "балык",
  "банан",
  "банда",
  "бандо",
  "банка",
  "барак",
  "баран",
  "барда",
  "баржа",
  "барий",
  "барин",
  "барич",
  "барка",
  "барон",
  "барыш",
  "баски",
  "басма",
  "басня",
  "басок",
  "баста",
  "батог",
  "батон",
  "батут",
  "бахча",
  "бачок",
  "башка",
  "башня",
  "бегун",
  "бедро",
  "бейка",
  "бекас",
  "бекон",
  "белек",
  "белка",
  "белок",
  "белье",
  "беляк",
  "берег",
  "берет",
  "бетон",
  "бивак",
  "бидон",
  "бизон",
  "билет",
  "бином",
  "биржа",
  "бирка",
  "бирюк",
  "бисер",
  "битва",
  "битюг",
  "благо",
  "блажь",
  "бланк",
  "блеск",
  "блоха",
  "блуза",
  "блюдо",
  "бляха",
  "бобер",
  "бобик",
  "богач",
  "божба",
  "божок",
  "бойня",
  "бокал",
  "болид",
  "бомба",
  "бонна",
  "бордо",
  "борец",
  "боров",
  "босяк",
  "ботва",
  "бочар",
  "бочка",
  "браво",
  "брага",
  "брада",
  "брань",
  "брасс",
  "бремя",
  "брешь",
  "бровь",
  "броня",
  "брысь",
  "брюки",
  "брюхо",
  "бубен",
  "бубны",
  "бугай",
  "бугор",
  "будка",
  "буква",
  "букет",
  "букле",
  "букса",
  "булат",
  "булка",
  "бульк",
  "бурав",
  "бурак",
  "буран",
  "бурда",
  "бурка",
  "бурун",
  "бутан",
  "бутон",
  "бутуз",
  "буфер",
  "буфет",
  "бухта",
  "быдло",
  "былье",
  "бытие",
  "бытье",
  "бычок",
  "бювар",
  "вагон",
  "вазон",
  "вакса",
  "валек",
  "валет",
  "валик",
  "валка",
  "валок",
  "валуй",
  "валун",
  "вальс",
  "ванна",
  "ватин",
  "ватка",
  "вафля",
  "вахта",
  "вверх",
  "вдова",
  "ведро",
  "вежда",
  "везде",
  "векша",
  "велик",
  "велюр",
  "венец",
  "веник",
  "венок",
  "вепрь",
  "верба",
  "верфь",
  "верша",
  "весло",
  "весна",
  "весть",
  "ветвь",
  "ветер",
  "ветка",
  "ветла",
  "вечер",
  "вечно",
  "вечор",
  "вешка",
  "вещун",
  "взвар",
  "взвод",
  "вздор",
  "вздох",
  "взлет",
  "взнос",
  "взрыв",
  "визир",
  "визит",
  "вилка",
  "вилла",
  "вилок",
  "виола",
  "вираж",
  "вирус",
  "виски",
  "висок",
  "вития",
  "виток",
  "вихор",
  "вихрь",
  "вишня",
  "вклад",
  "влага",
  "вобла",
  "водка",
  "вожак",
  "вождь",
  "вожжи",
  "возка",
  "возня",
  "возок",
  "война",
  "вокал",
  "волан",
  "волна",
  "волок",
  "волос",
  "волхв",
  "вольт",
  "вопль",
  "ворог",
  "ворон",
  "ворот",
  "ворох",
  "вотум",
  "вотще",
  "вояка",
  "враль",
  "врата",
  "время",
  "вроде",
  "всход",
  "втечь",
  "вуаль",
  "вчера",
  "въезд",
  "выбор",
  "вывих",
  "вывод",
  "вывоз",
  "выгиб",
  "выгон",
  "выдох",
  "выдра",
  "выезд",
  "вызов",
  "выкуп",
  "вынос",
  "выпад",
  "выпас",
  "выпот",
  "вырез",
  "выход",
  "вычет",
  "вышка",
  "вьюга",
  "гавот",
  "газик",
  "газон",
  "гайка",
  "галка",
  "галоп",
  "галун",
  "гамак",
  "гамма",
  "гараж",
  "гарем",
  "гарус",
  "гашиш",
  "гвалт",
  "гейша",
  "гелий",
  "гений",
  "герой",
  "гетры",
  "гетто",
  "гжель",
  "гидра",
  "гиена",
  "гипюр",
  "гирло",
  "гичка",
  "глава",
  "главк",
  "гладь",
  "глина",
  "глист",
  "глубь",
  "глушь",
  "глыба",
  "гнида",
  "гниль",
  "гобой",
  "говор",
  "гогот",
  "годок",
  "голец",
  "голик",
  "голод",
  "голос",
  "голыш",
  "голье",
  "гольф",
  "гомон",
  "гонец",
  "гонка",
  "гонор",
  "гопак",
  "горец",
  "горка",
  "горло",
  "город",
  "горох",
  "гость",
  "гофре",
  "грамм",
  "гранд",
  "грань",
  "графа",
  "греза",
  "греко",
  "греча",
  "грива",
  "грипп",
  "гроза",
  "гросс",
  "груда",
  "грудь",
  "грунт",
  "груша",
  "грыжа",
  "гряда",
  "грязь",
  "гуано",
  "гуашь",
  "губка",
  "гудок",
  "гуляш",
  "гумно",
  "гурия",
  "гусак",
  "гусар",
  "гусли",
  "гюрза",
  "давка",
  "дамба",
  "дамка",
  "дверь",
  "дебет",
  "дебил",
  "дебит",
  "дебош",
  "дебри",
  "дебют",
  "девиз",
  "девка",
  "деизм",
  "деист",
  "декан",
  "дележ",
  "делец",
  "демон",
  "денди",
  "дерби",
  "десна",
  "десть",
  "детва",
  "детка",
  "дефис",
  "дзюдо",
  "диван",
  "диета",
  "динар",
  "динго",
  "дичок",
  "длань",
  "длина",
  "днище",
  "добре",
  "добро",
  "довод",
  "догма",
  "дождь",
  "дозор",
  "докер",
  "домна",
  "домра",
  "донец",
  "донка",
  "донор",
  "донос",
  "доска",
  "досуг",
  "досыл",
  "досье",
  "доход",
  "дочка",
  "драга",
  "драже",
  "драка",
  "драла",
  "драма",
  "древо",
  "дрейф",
  "дрель",
  "дрема",
  "дробь",
  "дрова",
  "дрога",
  "дрожь",
  "дрозд",
  "дрофа",
  "дрянь",
  "дубль",
  "дубье",
  "дудка",
  "дужка",
  "дукат",
  "думка",
  "дупло",
  "дурак",
  "дутье",
  "духан",
  "душка",
  "душок",
  "дуэль",
  "дылда",
  "дымка",
  "дымок",
  "дырка",
  "дышло",
  "дюшес",
  "дятел",
  "евнух",
  "егерь",
  "егоза",
  "ежиха",
  "ездка",
  "ездок",
  "ересь",
  "есаул",
  "жабры",
  "жажда",
  "жакан",
  "жакет",
  "жарок",
  "жатва",
  "жатка",
  "жвала",
  "желоб",
  "желчь",
  "жених",
  "жердь",
  "жерех",
  "жерло",
  "жесть",
  "жетон",
  "живец",
  "живот",
  "жизнь",
  "жилет",
  "жилец",
  "жилка",
  "жилой",
  "жилье",
  "жираф",
  "житие",
  "житье",
  "жница",
  "жокей",
  "жулик",
  "жупан",
  "жупел",
  "жучка",
  "жучок",
  "забег",
  "забой",
  "забор",
  "завал",
  "завет",
  "завод",
  "завоз",
  "завуч",
  "загар",
  "загиб",
  "загон",
  "задел",
  "задок",
  "задор",
  "заезд",
  "зажим",
  "зажор",
  "зазор",
  "заика",
  "зайка",
  "заказ",
  "закал",
  "закат",
  "закон",
  "закут",
  "залеж",
  "залив",
  "залог",
  "залом",
  "замок",
  "замор",
  "замша",
  "занос",
  "запад",
  "запал",
  "запас",
  "запах",
  "запев",
  "запой",
  "запор",
  "зараз",
  "зарез",
  "зарок",
  "заряд",
  "засов",
  "засол",
  "затея",
  "затон",
  "затор",
  "заумь",
  "заход",
  "зачес",
  "зачет",
  "зачин",
  "звено",
  "зверь",
  "зебра",
  "зевок",
  "зелье",
  "земля",
  "зенит",
  "зерно",
  "зефир",
  "зипун",
  "злато",
  "злоба",
  "злюка",
  "знамя",
  "зубец",
  "зубок",
  "зурна",
  "зыбка",
  "зыбун",
  "иваси",
  "ивняк",
  "игрец",
  "игрок",
  "игрун",
  "идеал",
  "идиот",
  "иерей",
  "ижица",
  "избач",
  "извив",
  "извод",
  "извоз",
  "изгиб",
  "изгой",
  "излет",
  "излом",
  "изъян",
  "изыск",
  "изюбр",
  "икона",
  "икота",
  "индус",
  "индюк",
  "инжир",
  "интер",
  "инъяз",
  "иприт",
  "ирбис",
  "искра",
  "искус",
  "ислам",
  "испуг",
  "истец",
  "исток",
  "исход",
  "итого",
  "ишиас",
  "кабак",
  "кабан",
  "кагор",
  "кадет",
  "кадка",
  "кадык",
  "казак",
  "казан",
  "казна",
  "казнь",
  "казус",
  "кайло",
  "кайма",
  "какао",
  "калан",
  "калач",
  "калий",
  "калым",
  "камея",
  "камин",
  "камка",
  "камса",
  "камыш",
  "канал",
  "канат",
  "канва",
  "канон",
  "каноэ",
  "канун",
  "канюк",
  "капля",
  "капор",
  "капот",
  "капут",
  "карат",
  "карга",
  "карма",
  "карст",
  "карта",
  "каска",
  "касса",
  "каста",
  "катар",
  "катер",
  "катет",
  "катод",
  "каток",
  "катыш",
  "качка",
  "кашка",
  "кашне",
  "кашпо",
  "каюта",
  "квант",
  "кварц",
  "квота",
  "кегли",
  "келья",
  "кенаф",
  "кепка",
  "кефир",
  "кушью",
  "кивер",
  "кивок",
  "кизил",
  "кижуч",
  "кизяк",
  "кинза",
  "киоск",
  "кирза",
  "кирка",
  "кисет",
  "кисея",
  "киска",
  "киста",
  "кисть",
  "кичка",
  "кишка",
  "кладь",
  "клака",
  "класс",
  "клерк",
  "клест",
  "клеть",
  "клещи",
  "клика",
  "клише",
  "клоун",
  "клуша",
  "клюка",
  "кляча",
  "кнехт",
  "книга",
  "князь",
  "кобза",
  "кобра",
  "ковер",
  "кожан",
  "кожух",
  "козел",
  "койка",
  "койот",
  "кокки",
  "кокон",
  "кокос",
  "колба",
  "колер",
  "колея",
  "колит",
  "колли",
  "колок",
  "колос",
  "колун",
  "колье",
  "кольт",
  "комар",
  "комик",
  "комод",
  "комок",
  "конек",
  "конец",
  "конка",
  "конус",
  "конюх",
  "копер",
  "копия",
  "копна",
  "копун",
  "копыл",
  "копье",
  "коран",
  "корда",
  "кореш",
  "корка",
  "корма",
  "короб",
  "корье",
  "косец",
  "космы",
  "кость",
  "косяк",
  "котел",
  "котик",
  "кофта",
  "кочан",
  "кочик",
  "кочка",
  "кошка",
  "кошма",
  "кощей",
  "краги",
  "кража",
  "краля",
  "краса",
  "кредо",
  "креол",
  "крепь",
  "крест",
  "криль",
  "кровь",
  "кроль",
  "крона",
  "кросс",
  "кроха",
  "круиз",
  "крупа",
  "круча",
  "крыло",
  "крыса",
  "крыша",
  "кубик",
  "кубок",
  "кудри",
  "кузен",
  "кузов",
  "кукиш",
  "кукла",
  "кулак",
  "кулан",
  "кулек",
  "кулеш",
  "кулик",
  "кулич",
  "кулон",
  "культ",
  "кумач",
  "кумир",
  "кумыс",
  "кунак",
  "купец",
  "купля",
  "купол",
  "купон",
  "кураж",
  "курия",
  "курок",
  "кусок",
  "кутеж",
  "куток",
  "кутья",
  "кухня",
  "кучер",
  "кушак",
  "кювет",
  "лабаз",
  "лаваш",
  "лавка",
  "лавра",
  "ладан",
  "ладья",
  "лазер",
  "лайка",
  "лакей",
  "лампа",
  "лапка",
  "лапта",
  "лапша",
  "ларек",
  "ларец",
  "ласка",
  "лассо",
  "латка",
  "латук",
  "лафит",
  "левак",
  "левша",
  "легат",
  "лежак",
  "лежка",
  "лежмя",
  "лейка",
  "лемех",
  "лемма",
  "лемур",
  "лента",
  "ленца",
  "лепет",
  "лепка",
  "лепта",
  "леска",
  "лесть",
  "летка",
  "леток",
  "летун",
  "леший",
  "лиана",
  "ливер",
  "лидер",
  "ликер",
  "лилия",
  "лиман",
  "лимит",
  "лимон",
  "лимфа",
  "линек",
  "линза",
  "линия",
  "лирик",
  "литер",
  "литье",
  "лихач",
  "лицей",
  "лишай",
  "лишек",
  "лобби",
  "лобок",
  "ловец",
  "ловля",
  "лодка",
  "ложка",
  "ложок",
  "локон",
  "ломка",
  "лопух",
  "лоток",
  "лотос",
  "лоция",
  "лошак",
  "лубок",
  "лужок",
  "лузга",
  "лунка",
  "лучок",
  "лыжня",
  "лычко",
  "льяло",
  "люнет",
  "люпин",
  "лютик",
  "лютня",
  "ляжка",
  "лямка",
  "ляпис",
  "магий",
  "магия",
  "магма",
  "мадам",
  "маета",
  "мажор",
  "мазня",
  "мазок",
  "мазут",
  "майка",
  "майна",
  "майор",
  "макар",
  "макет",
  "макси",
  "малек",
  "малец",
  "малыш",
  "маляр",
  "мамка",
  "манго",
  "манеж",
  "манер",
  "мание",
  "мания",
  "манка",
  "манна",
  "манок",
  "манси",
  "манто",
  "манту",
  "марал",
  "марка",
  "марля",
  "маска",
  "масло",
  "масон",
  "масса",
  "масть",
  "матка",
  "мафия",
  "махра",
  "мачта",
  "медик",
  "медяк",
  "мезга",
  "мелок",
  "мерин",
  "мерка",
  "месса",
  "место",
  "месть",
  "месяц",
  "метан",
  "метил",
  "метис",
  "метка",
  "метла",
  "метод",
  "метро",
  "мечта",
  "мешок",
  "мидия",
  "минер",
  "минор",
  "минус",
  "миома",
  "мираж",
  "мирра",
  "миска",
  "митра",
  "мишка",
  "мойва",
  "мойка",
  "молва",
  "молот",
  "молох",
  "монах",
  "мопед",
  "морда",
  "мороз",
  "моряк",
  "мосол",
  "мотив",
  "мотка",
  "мотня",
  "моток",
  "мотор",
  "мохер",
  "мочка",
  "мошка",
  "мошна",
  "мразь",
  "мужик",
  "музей",
  "мулат",
  "мулла",
  "муляж",
  "мумие",
  "мумия",
  "мураш",
  "мурка",
  "мурло",
  "мусор",
  "муфта",
  "мушка",
  "мымра",
  "мысик",
  "мысль",
  "мышка",
  "мышца",
  "мякиш",
  "мялка",
  "мямля",
  "мятеж",
  "набат",
  "набег",
  "набор",
  "навар",
  "навес",
  "навет",
  "навоз",
  "навой",
  "навык",
  "наган",
  "нагар",
  "нагул",
  "надел",
  "надой",
  "наезд",
  "нажиг",
  "нажим",
  "нажин",
  "назем",
  "наказ",
  "накат",
  "налет",
  "налив",
  "налим",
  "налог",
  "намаз",
  "намек",
  "намет",
  "намыв",
  "нанка",
  "нанос",
  "напев",
  "напор",
  "нарез",
  "народ",
  "нарты",
  "нарыв",
  "наряд",
  "насос",
  "натек",
  "наука",
  "нахал",
  "нация",
  "начес",
  "начет",
  "наяда",
  "невод",
  "недра",
  "недуг",
  "нейти",
  "некто",
  "нерка",
  "нерпа",
  "несть",
  "несун",
  "нетто",
  "нефть",
  "нечто",
  "нивхи",
  "низок",
  "никто",
  "нимфа",
  "нитка",
  "ничто",
  "ножик",
  "ножка",
  "ножны",
  "номер",
  "норка",
  "норма",
  "норов",
  "носик",
  "носка",
  "носок",
  "нужда",
  "нулик",
  "нутро",
  "нырок",
  "нытик",
  "нюанс",
  "оазис",
  "обвал",
  "обвод",
  "обгон",
  "обзор",
  "обида",
  "обком",
  "облик",
  "облом",
  "обман",
  "обмен",
  "образ",
  "обрат",
  "обрез",
  "оброк",
  "обруб",
  "обруч",
  "обрыв",
  "обряд",
  "обувь",
  "обуза",
  "обход",
  "объем",
  "обыск",
  "овощь",
  "овраг",
  "овсюг",
  "овчар",
  "огонь",
  "огрех",
  "одежа",
  "одурь",
  "озеро",
  "озимь",
  "озноб",
  "океан",
  "окись",
  "оклад",
  "оклик",
  "оковы",
  "окрик",
  "окрол",
  "округ",
  "оксид",
  "октет",
  "окунь",
  "олень",
  "олива",
  "олимп",
  "олифа",
  "олово",
  "ольха",
  "омега",
  "омела",
  "омлет",
  "омуль",
  "оникс",
  "онуча",
  "опала",
  "опара",
  "опека",
  "опера",
  "опись",
  "опиум",
  "оплот",
  "опоек",
  "опока",
  "опора",
  "опрос",
  "оптик",
  "орава",
  "орало",
  "орган",
  "оргия",
  "орден",
  "ордер",
  "ореол",
  "орлан",
  "орлец",
  "осада",
  "осень",
  "осетр",
  "осина",
  "оскал",
  "ослик",
  "осмос",
  "особа",
  "особь",
  "осока",
  "остов",
  "остол",
  "осыпь",
  "отава",
  "отара",
  "отбой",
  "отбор",
  "отвал",
  "отвар",
  "отвес",
  "ответ",
  "отвод",
  "отгон",
  "отгул",
  "отдел",
  "отдых",
  "отель",
  "отжиг",
  "отзыв",
  "откос",
  "откуп",
  "отлет",
  "отлив",
  "отпор",
  "отрез",
  "отрог",
  "отрок",
  "отруб",
  "отрыв",
  "отряд",
  "отсев",
  "отсек",
  "отсос",
  "отток",
  "отход",
  "отчет",
  "отчим",
  "отъем",
  "офеня",
  "офорт",
  "офсет",
  "охота",
  "очерк",
  "очник",
  "падеж",
  "падуб",
  "пайка",
  "пакет",
  "пакля",
  "палас",
  "палач",
  "палаш",
  "палех",
  "палец",
  "палка",
  "панда",
  "панна",
  "панно",
  "панты",
  "папка",
  "парад",
  "парез",
  "парик",
  "пария",
  "паром",
  "парта",
  "парус",
  "парча",
  "парша",
  "пасмо",
  "паста",
  "пасть",
  "пасха",
  "патер",
  "пауза",
  "пафос",
  "пахта",
  "пацан",
  "пачка",
  "пашня",
  "певец",
  "пекан",
  "пекло",
  "пемза",
  "пенал",
  "пение",
  "пенис",
  "пенка",
  "пенни",
  "пепел",
  "перец",
  "перси",
  "перст",
  "персы",
  "песец",
  "песнь",
  "песня",
  "песок",
  "петит",
  "петля",
  "петух",
  "пехом",
  "печка",
  "пешка",
  "пешня",
  "пиала",
  "пиано",
  "пижон",
  "пикап",
  "пикет",
  "пикша",
  "пилка",
  "пилот",
  "пинок",
  "пинта",
  "пират",
  "пирог",
  "писец",
  "питон",
  "питье",
  "пихта",
  "пицца",
  "пищик",
  "пламя",
  "пласт",
  "плата",
  "плато",
  "плаун",
  "плаха",
  "плева",
  "племя",
  "плеск",
  "плеть",
  "плечо",
  "плешь",
  "плита",
  "плица",
  "плоть",
  "побег",
  "побои",
  "повал",
  "повар",
  "повод",
  "подол",
  "поезд",
  "пожар",
  "пожня",
  "позер",
  "позор",
  "позыв",
  "поиск",
  "пойло",
  "пойма",
  "показ",
  "покер",
  "покои",
  "покой",
  "покос",
  "покус",
  "полба",
  "полет",
  "полив",
  "полип",
  "полис",
  "полка",
  "полог",
  "полоз",
  "полок",
  "полюс",
  "помет",
  "помин",
  "помои",
  "помол",
  "помор",
  "помпа",
  "понос",
  "пончо",
  "попка",
  "порез",
  "порей",
  "порка",
  "порог",
  "порок",
  "порох",
  "порча",
  "порыв",
  "посад",
  "посев",
  "посол",
  "посох",
  "посул",
  "посыл",
  "потек",
  "поток",
  "потоп",
  "поход",
  "почва",
  "почет",
  "почин",
  "почка",
  "почта",
  "пошиб",
  "поэма",
  "право",
  "прайд",
  "праща",
  "предо",
  "прель",
  "пресс",
  "преть",
  "прием",
  "прима",
  "принц",
  "причт",
  "приют",
  "проба",
  "проем",
  "проза",
  "просо",
  "прыть",
  "прядь",
  "пряжа",
  "пряха",
  "псарь",
  "псина",
  "птица",
  "пугач",
  "пудра",
  "пузан",
  "пульс",
  "пульт",
  "пункт",
  "пупок",
  "пурга",
  "путик",
  "пучок",
  "пушка",
  "пушок",
  "пчела",
  "пшено",
  "пыжик",
  "пырей",
  "пытка",
  "пышка",
  "пьеса",
  "пьянь",
  "пясть",
  "пятак",
  "пятка",
  "пятно",
  "пяток",
  "радар",
  "раджа",
  "радий",
  "радио",
  "радон",
  "разор",
  "разум",
  "район",
  "ралли",
  "рамка",
  "рампа",
  "ранет",
  "ранец",
  "ранчо",
  "ратай",
  "раунд",
  "рафик",
  "рахит",
  "рация",
  "рачок",
  "рвань",
  "рвота",
  "ребро",
  "ребус",
  "регби",
  "редис",
  "редут",
  "режим",
  "резак",
  "резец",
  "резка",
  "резня",
  "резон",
  "резус",
  "рейка",
  "рельс",
  "ремиз",
  "рента",
  "репей",
  "ретро",
  "речка",
  "решка",
  "рикша",
  "ритор",
  "рифма",
  "робот",
  "ровня",
  "рогач",
  "рогоз",
  "родео",
  "родич",
  "родня",
  "рожок",
  "рожон",
  "розан",
  "розга",
  "розно",
  "рознь",
  "рокот",
  "ролик",
  "роман",
  "рондо",
  "ропак",
  "ропот",
  "ротор",
  "рохля",
  "рояль",
  "ртуть",
  "рубеж",
  "рубец",
  "рубин",
  "рубка",
  "рубль",
  "ругня",
  "ружье",
  "руина",
  "рукав",
  "рулет",
  "рулон",
  "рупия",
  "рупор",
  "русак",
  "русло",
  "ручей",
  "ручка",
  "рыбак",
  "рыбец",
  "рывок",
  "рында",
  "рынок",
  "рысак",
  "рысца",
  "рычаг",
  "рэкет",
  "рюмка",
  "рядно",
  "ряска",
  "саами",
  "сабля",
  "саван",
  "садик",
  "садок",
  "сазан",
  "сайда",
  "сайка",
  "сайра",
  "сакля",
  "салат",
  "салки",
  "салон",
  "салоп",
  "салют",
  "саман",
  "самбо",
  "самец",
  "самка",
  "самум",
  "санки",
  "сапер",
  "сарай",
  "саржа",
  "сарыч",
  "сатин",
  "сатир",
  "сауна",
  "сахар",
  "сачок",
  "сброд",
  "сбруя",
  "свара",
  "сваха",
  "свеча",
  "свиль",
  "свист",
  "свита",
  "свора",
  "свояк",
  "связь",
  "сдача",
  "сдвиг",
  "сдоба",
  "сеанс",
  "север",
  "седло",
  "седок",
  "сезон",
  "секач",
  "секта",
  "семга",
  "семья",
  "сенаж",
  "сенат",
  "сепия",
  "серия",
  "серна",
  "серсо",
  "сетка",
  "сечка",
  "сивка",
  "сивуч",
  "сидор",
  "силач",
  "силок",
  "силос",
  "синод",
  "синус",
  "синяк",
  "сирин",
  "сироп",
  "ситец",
  "ситро",
  "сифон",
  "скала",
  "скань",
  "скарб",
  "сквер",
  "скетч",
  "скирд",
  "скифы",
  "склад",
  "склеп",
  "склон",
  "скоба",
  "скрип",
  "скука",
  "скула",
  "скунс",
  "слава",
  "слайд",
  "слега",
  "слеза",
  "слива",
  "слизь",
  "слово",
  "слуга",
  "слюда",
  "слюна",
  "смена",
  "смерд",
  "смерч",
  "смесь",
  "смета",
  "смола",
  "смотр",
  "смута",
  "смысл",
  "снедь",
  "сноха",
  "собес",
  "собор",
  "совет",
  "совка",
  "совок",
  "содом",
  "сойка",
  "сокол",
  "солод",
  "солон",
  "соляр",
  "сонет",
  "сопка",
  "сопло",
  "сопля",
  "сорго",
  "сосед",
  "соска",
  "сосна",
  "сосок",
  "сосуд",
  "сосун",
  "сосцы",
  "сотка",
  "сотня",
  "софит",
  "сошка",
  "спазм",
  "спесь",
  "спина",
  "спирт",
  "сплин",
  "спора",
  "спорт",
  "спрос",
  "спрут",
  "спуск",
  "среда",
  "ссора",
  "ссуда",
  "стадо",
  "сталь",
  "старт",
  "ствол",
  "створ",
  "стезя",
  "стела",
  "стена",
  "стенд",
  "степь",
  "стечь",
  "стиль",
  "стоик",
  "столб",
  "столп",
  "стопа",
  "стояк",
  "страж",
  "страх",
  "стриж",
  "строй",
  "строп",
  "струг",
  "струп",
  "струя",
  "стужа",
  "ступа",
  "судак",
  "судия",
  "судно",
  "судок",
  "судья",
  "суета",
  "сукно",
  "сумка",
  "сумма",
  "сурик",
  "сурок",
  "сусек",
  "сусло",
  "сутаж",
  "сутки",
  "суфле",
  "сухой",
  "сучка",
  "сучок",
  "сушка",
  "сфера",
  "схема",
  "схима",
  "сцена",
  "счеты",
  "съезд",
  "сынок",
  "сырец",
  "сырок",
  "сырье",
  "сычуг",
  "сыщик",
  "сюжет",
  "сюита",
  "табак",
  "табло",
  "табор",
  "табун",
  "тавро",
  "таган",
  "тайга",
  "тайна",
  "такса",
  "такси",
  "талер",
  "талия",
  "талон",
  "тальк",
  "танго",
  "танец",
  "танин",
  "тапер",
  "тапир",
  "таран",
  "тариф",
  "таска",
  "тафта",
  "тахта",
  "тачка",
  "тварь",
  "твист",
  "театр",
  "тезис",
  "тезка",
  "теизм",
  "текст",
  "телец",
  "телик",
  "телка",
  "телок",
  "тембр",
  "тенек",
  "тенор",
  "тепло",
  "терем",
  "терка",
  "тесак",
  "тесто",
  "тесть",
  "тетка",
  "течка",
  "тиара",
  "типаж",
  "типун",
  "тираж",
  "тиран",
  "тиски",
  "титан",
  "титло",
  "титул",
  "ткань",
  "товар",
  "толки",
  "толпа",
  "толща",
  "томат",
  "тонус",
  "топаз",
  "топка",
  "топор",
  "топот",
  "торба",
  "торги",
  "торец",
  "торос",
  "тоска",
  "тотем",
  "точка",
  "трава",
  "тракт",
  "транс",
  "трата",
  "траур",
  "треба",
  "трель",
  "треск",
  "трест",
  "треть",
  "треух",
  "трефы",
  "триер",
  "трико",
  "тромб",
  "тропа",
  "трояк",
  "труба",
  "трусы",
  "труха",
  "трюмо",
  "тубус",
  "тузик",
  "тулуп",
  "тулья",
  "тумак",
  "туман",
  "тумба",
  "тунец",
  "тупик",
  "турка",
  "турне",
  "туфли",
  "туфта",
  "тушка",
  "тщета",
  "тыква",
  "тычок",
  "тюбик",
  "тютюн",
  "тюфяк",
  "тягач",
  "тягло",
  "тяжба",
  "тяпка",
  "уазик",
  "убыль",
  "уголь",
  "угорь",
  "удаль",
  "удача",
  "удила",
  "узина",
  "узник",
  "уклад",
  "уклон",
  "укора",
  "укроп",
  "уксус",
  "улика",
  "улита",
  "улица",
  "умник",
  "умора",
  "унция",
  "упрек",
  "упырь",
  "усики",
  "успех",
  "устав",
  "устой",
  "уступ",
  "устье",
  "утеря",
  "утеха",
  "утиль",
  "утица",
  "ухарь",
  "ухват",
  "учеба",
  "учхоз",
  "ушник",
  "ущерб",
  "фавор",
  "фагот",
  "фазан",
  "фазис",
  "факел",
  "факир",
  "фалда",
  "фальц",
  "фанза",
  "фасад",
  "фаска",
  "фасон",
  "фауна",
  "фаянс",
  "фенил",
  "фенол",
  "ферзь",
  "ферма",
  "феска",
  "фетиш",
  "фиакр",
  "фибра",
  "фижмы",
  "физик",
  "фикус",
  "филей",
  "филер",
  "филин",
  "филон",
  "фильм",
  "финал",
  "финик",
  "финиш",
  "финка",
  "фиорд",
  "фирма",
  "фишка",
  "фланг",
  "флешь",
  "флирт",
  "флокс",
  "флора",
  "фляга",
  "фокус",
  "фомка",
  "форма",
  "форте",
  "форум",
  "фотон",
  "фраза",
  "франк",
  "франт",
  "фрахт",
  "фреза",
  "френч",
  "фронт",
  "фрукт",
  "фугас",
  "фужер",
  "фуляр",
  "фураж",
  "фурия",
  "фурор",
  "фьорд",
  "фьють",
  "фюрер",
  "хайло",
  "халат",
  "халва",
  "халда",
  "халиф",
  "хамса",
  "хамье",
  "ханжа",
  "ханты",
  "хворь",
  "хвост",
  "херес",
  "хиляк",
  "химик",
  "химия",
  "хинди",
  "хинин",
  "хиппи",
  "хитон",
  "хлыст",
  "хлюст",
  "хлябь",
  "хмель",
  "хобби",
  "хобот",
  "ходка",
  "ходок",
  "холка",
  "холод",
  "холоп",
  "холст",
  "холуй",
  "хомут",
  "хомяк",
  "хорал",
  "хорда",
  "хорей",
  "хорек",
  "хором",
  "хохма",
  "хохол",
  "хохот",
  "хруст",
  "хунта",
  "хурал",
  "хурма",
  "хутор",
  "цапка",
  "цапля",
  "цвето",
  "цевка",
  "цевье",
  "цедра",
  "центр",
  "цепка",
  "цибик",
  "цикля",
  "цинга",
  "циник",
  "цитра",
  "цифра",
  "цокот",
  "цукат",
  "цуцик",
  "цыпка",
  "чабан",
  "чадра",
  "чайка",
  "чалка",
  "чалма",
  "чарка",
  "часть",
  "чашка",
  "чекан",
  "челка",
  "чепец",
  "червь",
  "черед",
  "череп",
  "чернь",
  "черта",
  "честь",
  "четки",
  "чехол",
  "чечет",
  "чешки",
  "чешуя",
  "чибис",
  "чижик",
  "чирей",
  "чирок",
  "число",
  "читка",
  "чрево",
  "чреда",
  "чрезо",
  "чтиво",
  "чубук",
  "чугун",
  "чудак",
  "чудик",
  "чужак",
  "чуйка",
  "чулан",
  "чулок",
  "чумак",
  "чурек",
  "чурка",
  "чуток",
  "чутье",
  "чушка",
  "шабаш",
  "шавка",
  "шайба",
  "шайка",
  "шакал",
  "шалаш",
  "шалун",
  "шаман",
  "шамот",
  "шанец",
  "шапка",
  "шарах",
  "шарик",
  "шасси",
  "шасть",
  "шатен",
  "шатер",
  "шатия",
  "шатун",
  "шафер",
  "шахта",
  "шашка",
  "шашни",
  "шваль",
  "шевро",
  "шейка",
  "шелом",
  "шельф",
  "шемая",
  "шепот",
  "шериф",
  "шизик",
  "шинок",
  "шипун",
  "ширма",
  "шитье",
  "шифер",
  "шифон",
  "шихта",
  "шишак",
  "шишка",
  "шкала",
  "шквал",
  "шкода",
  "школа",
  "шкура",
  "шланг",
  "шлейф",
  "шлюха",
  "шляпа",
  "шмель",
  "шорня",
  "шорох",
  "шорты",
  "шоссе",
  "шофер",
  "шпага",
  "шпала",
  "шпана",
  "шпаты",
  "шпиль",
  "шпион",
  "шпора",
  "шприц",
  "шпунт",
  "шрифт",
  "штамп",
  "штаны",
  "штиль",
  "штифт",
  "штора",
  "шторм",
  "штраф",
  "штрек",
  "штрих",
  "штука",
  "штурм",
  "штырь",
  "шулер",
  "шумок",
  "шурин",
  "шуруп",
  "шутка",
  "шушун",
  "шхеры",
  "шхуна",
  "щегол",
  "щекот",
  "щелка",
  "щелок",
  "щенок",
  "щепка",
  "щетка",
  "щечка",
  "щипок",
  "щипцы",
  "щиток",
  "эвены",
  "эклер",
  "экран",
  "элита",
  "эмаль",
  "эпоха",
  "эрзац",
  "эркер",
  "эскиз",
  "эстет",
  "этика",
  "этнос",
  "юдоль",
  "юкола",
  "юниор",
  "юнкер",
  "юннат",
  "юноша",
  "юрист",
  "ябеда",
  "ягель",
  "ягода",
  "ягуар",
  "яичко",
  "якорь",
  "ямина",
  "ямщик",
  "ярлык",
  "ярыга",
  "ясень",
  "яство",
  "ястык",
  "яхонт"
];

export const nonNouns = [
  "акать",
  "алеть",
  "арабы",
  "ахать",
  "бармы",
  "бачки",
  "бдеть",
  "бегом",
  "белый",
  "бивни",
  "битки",
  "битлы",
  "битый",
  "божий",
  "боком",
  "более",
  "борть",
  "босой",
  "брать",
  "брить",
  "будет",
  "будни",
  "будто",
  "букли",
  "бурки",
  "бурый",
  "бутсы",
  "былой",
  "валки",
  "ваять",
  "вбить",
  "вброд",
  "ввиду",
  "ввить",
  "вволю",
  "ввысь",
  "вдали",
  "вдаль",
  "вдвое",
  "вдеть",
  "вдоль",
  "вдруг",
  "везти",
  "вести",
  "вещий",
  "веять",
  "вживе",
  "взять",
  "вирши",
  "витой",
  "вкось",
  "вкруг",
  "вкупе",
  "власы",
  "влево",
  "влечь",
  "влить",
  "вмять",
  "внизу",
  "внове",
  "вновь",
  "внять",
  "вовек",
  "вовне",
  "вовсе",
  "вовсю",
  "возле",
  "войти",
  "впору",
  "впрок",
  "враки",
  "врать",
  "врозь",
  "врыть",
  "всего",
  "вслед",
  "вслух",
  "всюду",
  "всяко",
  "втора",
  "втрое",
  "втуне",
  "вчуже",
  "вширь",
  "вшить",
  "въявь",
  "выйти",
  "вялый",
  "вящий",
  "галлы",
  "гнать",
  "гнить",
  "гнуть",
  "голый",
  "греки",
  "греть",
  "грязи",
  "гунны",
  "гусем",
  "давно",
  "далее",
  "даром",
  "дикий",
  "длить",
  "доить",
  "дойти",
  "долой",
  "домой",
  "дотла",
  "драть",
  "дроги",
  "дурно",
  "дутый",
  "дыбом",
  "дюжий",
  "евреи",
  "едкий",
  "ежели",
  "езжай",
  "екать",
  "емкий",
  "ехать",
  "жалко",
  "ждать",
  "живой",
  "жрать",
  "задом",
  "зайти",
  "засим",
  "затем",
  "зачем",
  "звать",
  "здесь",
  "зимой",
  "зиять",
  "злеть",
  "злить",
  "знать",
  "зразы",
  "зреть",
  "извне",
  "икать",
  "иметь",
  "истый",
  "иудеи",
  "ихний",
  "кадры",
  "каков",
  "какой",
  "карий",
  "кзади",
  "книзу",
  "когда",
  "козлы",
  "козни",
  "корчи",
  "косой",
  "кроме",
  "крыть",
  "кряду",
  "курды",
  "курсы",
  "куцый",
  "лаять",
  "лгать",
  "левый",
  "лезть",
  "летом",
  "литой",
  "лихой",
  "лохмы",
  "лысый",
  "лычки",
  "любой",
  "лютый",
  "мавры",
  "малый",
  "махры",
  "менее",
  "мести",
  "мигом",
  "милый",
  "млеть",
  "мнить",
  "много",
  "может",
  "можно",
  "молча",
  "мчать",
  "мыший",
  "мятый",
  "набок",
  "навек",
  "нагой",
  "назад",
  "назло",
  "найти",
  "наяву",
  "негде",
  "негры",
  "некий",
  "немой",
  "немцы",
  "ненцы",
  "нести",
  "нигде",
  "низом",
  "никак",
  "ничей",
  "ничья",
  "нищий",
  "новый",
  "ночью",
  "нынче",
  "обить",
  "обуть",
  "общий",
  "овощи",
  "одеть",
  "ожечь",
  "ожить",
  "оземь",
  "окать",
  "около",
  "омыть",
  "опить",
  "оптом",
  "опять",
  "орать",
  "отечь",
  "отцов",
  "отчий",
  "охать",
  "очень",
  "очный",
  "ошуюю",
  "пасти",
  "паять",
  "пегий",
  "перед",
  "пеший",
  "плыть",
  "пнуть",
  "подле",
  "позже",
  "поить",
  "пойти",
  "полон",
  "полый",
  "порой",
  "порою",
  "порты",
  "после",
  "потом",
  "почем",
  "почки",
  "почти",
  "прочь",
  "пусть",
  "путем",
  "пущий",
  "пятый",
  "пятью",
  "равно",
  "разве",
  "разом",
  "ранее",
  "расти",
  "рачий",
  "рвать",
  "рдеть",
  "реять",
  "ржать",
  "ровно",
  "русый",
  "рыбий",
  "рыжий",
  "рысий",
  "рябой",
  "рядом",
  "самый",
  "сбить",
  "сбоку",
  "сбыть",
  "сверх",
  "свить",
  "свыше",
  "сдать",
  "сдуру",
  "сдуть",
  "седой",
  "семью",
  "сербы",
  "серый",
  "сесть",
  "сеять",
  "сжать",
  "сжечь",
  "сжить",
  "сзади",
  "сивый",
  "сизый",
  "силой",
  "синий",
  "сирый",
  "сиять",
  "сколь",
  "слать",
  "слева",
  "слить",
  "слыть",
  "слышь",
  "слюни",
  "сметь",
  "смочь",
  "смрад",
  "смыть",
  "смять",
  "снизу",
  "снова",
  "снять",
  "сойти",
  "сопли",
  "сорок",
  "сотый",
  "спать",
  "спеть",
  "спечь",
  "сразу",
  "среди",
  "средь",
  "сроду",
  "срыть",
  "сряду",
  "ссечь",
  "стать",
  "столь",
  "стыть",
  "сущий",
  "сшить",
  "сырой",
  "сытый",
  "таить",
  "таков",
  "такой",
  "талый",
  "таять",
  "тихий",
  "ткать",
  "тлеть",
  "тогда",
  "тощий",
  "тугой",
  "тупой",
  "турки",
  "тюрки",
  "убить",
  "убыть",
  "увить",
  "удить",
  "уесть",
  "ужели",
  "узить",
  "узкий",
  "уметь",
  "умный",
  "умыть",
  "умять",
  "унять",
  "упечь",
  "усечь",
  "утечь",
  "утлый",
  "утром",
  "учить",
  "ушить",
  "ушкуй",
  "ушлый",
  "ушной",
  "финны",
  "хвать",
  "хилый",
  "ходом",
  "худой",
  "целый",
  "цыпки",
  "чалый",
  "часом",
  "чаять",
  "черви",
  "через",
  "чохом",
  "чтить",
  "чудом",
  "чужой",
  "чукчи",
  "чуять",
  "шагом",
  "шалый",
  "шведы",
  "шерпы",
  "шесть",
  "шитый",
  "щучий",
  "энный",
  "юлить",
  "юркий",
  "явить",
  "явный",
  "якобы",
  "якуты",
  "яркий",
  "ясный"
];