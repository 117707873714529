import styles from './Word.module.css';
import { Letter, type UseType } from './Letter';

export { UseType };

export type LetterType = {
    letter: string;
    type: UseType;
    broken?: boolean;
}

export type Letters = [ LetterType, LetterType, LetterType, LetterType, LetterType ]

type Props = {
    letters: Letters;
    onChange?: (letters: Letters) => void;
}

const WordComponent = ({ letters, onChange }: Props) => (
    <div className={styles.word}>
        {letters.map(({ letter, type, broken }, index) => (
            <Letter key={index} type={type} broken={broken} onChange={(newType) => {
                const newLetters: Letters = [...letters];
                newLetters[index] = { letter, type: newType };

                onChange?.(newLetters);
            }}>{letter}</Letter>
        ))}
    </div>
);

const EmptyWord = () => (
    <div className={styles.word}>
        {Array.from({ length: 5 }).map((_, index) => (
            <Letter.Empty key={index} />
        ))}
    </div>
)

export const Word = Object.assign(WordComponent, { Empty: EmptyWord });