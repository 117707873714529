import classNames from 'classnames';

import styles from './Letter.module.css';

export type UseType = 'wrong' | 'correct' | 'exact';

type Props = {
    children: string;
    type: UseType;
    broken?: boolean;
    onChange?: (type: UseType) => void;
}

const cellTypeStyle: Record<UseType, string> = {
    wrong: styles.wrong,
    correct: styles.correct,
    exact: styles.exact,
};

const nextType: Record<UseType, UseType> = {
    wrong: 'correct',
    correct: 'exact',
    exact: 'wrong',
}

const LetterComponent = ({ children, type, broken, onChange }: Props) => (
    <div className={classNames(styles.letter, cellTypeStyle[type], broken && styles.error)} onClick={() => onChange?.(nextType[type])}>{children}</div>
);

const EmptyLetter = () => (
    <div className={styles.letter} />
);

export const Letter = Object.assign(LetterComponent, {
    Empty: EmptyLetter
})