import styles from './Logo.module.css';

const LOGO_TEXT = '5БУКВ';

export const Logo = () => (
    <div className={styles.logo}>
        {LOGO_TEXT.split('').map((letter, index) => (
            <div key={index} className={styles.letter}>{letter}</div>
        ))}
    </div>
);
