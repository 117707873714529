import { useState, useMemo, useLayoutEffect } from 'react';

import styles from './App.module.css';
import { nouns, nonNouns } from '../../const/words';

import { Logo } from '../Logo';
import { Tag } from '../Tag';
import { Word, Letters, LetterType, UseType } from '../Word';
import { Button } from '../Button';
import { Link } from '../Link';
import { Modal } from '../Modal';
import { IconButton } from '../IconButton';

import { expectNever } from '../../utils';

export const App = () => {
  const [onlyNouns, setOnlyNouns] = useState(true);
  const [onlyActualWords, setOnlyActualWords] = useState(false);
  const [noUsedLetters, setNoUsedLetters] = useState(true);
  const [isCustomWordEnabled, setIsCustomWordEnabled] = useState(false);
  const [skipWords, setSkipWords] = useState(0);

  const [previous, setPrevious] = useState<Letters[]>([]);
  const allWords = useMemo(() => onlyNouns ? nouns : nouns.concat(nonNouns).sort(), [onlyNouns]);
  const words = useMemo(() => allWords.filter(createFilter(previous)), [allWords, previous]);

  const sortedWords = useMemo(() => {
    const lettersMap = getLettersMap(words);

    const exclude = getNotFoundLetters(previous);

    if (noUsedLetters) {
      exclude.push(...getFoundLetters(previous));
    }

    for(const letter of exclude) {
      delete lettersMap[letter];
    }

    return sortWords(onlyActualWords ? words : allWords, lettersMap);
  }, [words && onlyActualWords, noUsedLetters, previous]);
  const wordsToSkip = Math.min(skipWords, sortedWords.length - 1);
  const bestWord = sortedWords[wordsToSkip];

  const [customWord, setCustomWord] = useState<string>('');
  const currentWord = isCustomWordEnabled ? customWord : bestWord;

  const [isWordsShown, setIsWordsShown] = useState(false);
  const [isSettingsShown, setIsSettingsShown] = useState(false);
  const [isSettingsHintShown, setIsSettingsHintShown] = useState(false);
  const [isConflictsShown, setIsConflictsShown] = useState(false);

  const [lettersState, setLettersState] = useState(() => getDefaultLetters(currentWord, previous));
  const joinedLetters = lettersState.map(({ letter }) => letter).join('');
  const letters = joinedLetters === currentWord ? lettersState : getDefaultLetters(currentWord, previous);

  const fewWordsLeft = words.length < 10;
  useLayoutEffect(() => {
    if (fewWordsLeft) {
      setOnlyActualWords(true);
    }
  }, [fewWordsLeft])

  const reset = () => {
    setPrevious([]);
    setLettersState(getDefaultLetters(currentWord, previous));
    setOnlyActualWords(false);
    setSkipWords(0);
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <Logo />

          <div className={styles.toolbar}>
            <Tag onClick={() => setIsSettingsShown(true)} disabled={isCustomWordEnabled}>⚙️</Tag>
            <Tag active={wordsToSkip > 0} onClick={() => {
              if (wordsToSkip < sortedWords.length - 1) {
                setSkipWords(skipWords + 1);
              }
            }} disabled={isCustomWordEnabled || wordsToSkip >= sortedWords.length - 1}>⏩{wordsToSkip > 0 && ` ${wordsToSkip}`}</Tag>
            <Tag onClick={() => setSkipWords(0)} disabled={wordsToSkip == 0 || isCustomWordEnabled}>⏹️</Tag>
            <Tag active={isCustomWordEnabled} onClick={() => {
              if (isCustomWordEnabled) {
                setIsCustomWordEnabled(false);
                return;
              }

              const customWord = prompt('Ваше слово:', bestWord);

              if (!customWord) {
                return;
              }

              if (customWord?.length !== 5) {
                alert('Слово должно состоять из 5 букв!');
                return;
              }

              setIsCustomWordEnabled(true);
              setCustomWord(customWord.toLowerCase());
            }}>✏️</Tag>
          </div>
        </div>

        <div className={styles.body}>
          {previous.map((letters, index) => <Word key={index} letters={letters} />)}
          {words.length > 1 && <Word key={currentWord} letters={letters} onChange={newLetters => {
            const changed = newLetters
                                .map(({ letter, type }, index) => ({ letter, type, index }))
                                .filter(({ type }, index) => type !== letters[index].type)
                                .map(({ letter }) => letter);

            setLettersState(newLetters.map(letter => ({ ...letter, broken: changed.includes(letter.letter) ? false : letter.broken })) as Letters);
          }} />}
          {Array.from({ length: 5 - previous.length + (words.length > 1 ? 0 : 1) }).map(() => (
            <Word.Empty />
          ))}
        </div>

        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button primary grouped onClick={() => {
              const errors = validate(letters, previous);

              if (errors.length) {
                setLettersState(letters.map((letter, index) => ({ ...letter, broken: errors.includes(index) })) as Letters);
                setIsConflictsShown(true);
                return;
              }

              setPrevious(previous => [...previous, letters]);
              setIsCustomWordEnabled(false);
              setSkipWords(0);
            }}>Дальше</Button>
            <Button grouped onClick={reset}>Заново</Button>
          </div>
          <Link onClick={() => setIsWordsShown(true)}>Осталось слов: {words.length} ({formatPercent(words.length * 100 / allWords.length)}%)</Link>
        </div>
      </div>

      <Modal shown={isWordsShown} onClose={() => setIsWordsShown(false)}>{words.map(
        (word, index) => (<>{Boolean(index) && ", "}<Link onClick={() => {
          setIsCustomWordEnabled(true);
          setCustomWord(word);

          setIsWordsShown(false);
        }}>{word}</Link></>)
      )}</Modal>

      <Modal shown={words.length < 2}>
        <div className={styles.message}>
          {!words.length && <>К сожалению слово не найдено =(</>}
          {(words.length === 1) && <>Искомое слово: <div className={styles.result}>{words[0]}</div></>}
        </div>
        <div className={styles.buttons}>
          <Button primary rounded onClick={reset}>Начать заново</Button>
        </div>
      </Modal>

      <Modal shown={isConflictsShown}>
        <div className={styles.message}>
          Похоже, что мы забыли корректно отметить пару букв.
        </div>
        <div className={styles.buttons}>
          <Button primary rounded onClick={() => setIsConflictsShown(false)}>Точно</Button>
        </div>
      </Modal>

      <Modal shown={isSettingsShown} onClose={() => {
        setIsSettingsShown(false);
        setIsSettingsHintShown(false);
      }}>
        <div className={styles.buttons} style={{ alignItems: 'center' }}>
          <div className={styles.buttons}>
            <Button grouped rounded primary={onlyNouns} onClick={() => setOnlyNouns(toggle)} disabled={isCustomWordEnabled} nowrap>Сущ-e</Button>
            <Button grouped rounded primary={onlyActualWords} onClick={() => setOnlyActualWords(toggle)} disabled={isCustomWordEnabled}>Возможные слова</Button>
            <Button grouped rounded primary={noUsedLetters} onClick={() => setNoUsedLetters(toggle)} disabled={isCustomWordEnabled}>Новые буквы</Button>
          </div>
          <IconButton onClick={() => setIsSettingsHintShown(toggle)}>ℹ️</IconButton>
        </div>

        {isSettingsHintShown && <div className={styles.hint}>
          <div>"Существительные" - пытаться угадать только существительные в единственном лице именительном падеже.</div>
          <div>"Возможные слова" - выбирать следующее слово только среди тех слов, которые еще могут быть искомым словом.</div>
          <div>"Новые буквы" - не учитывать вес быкв, которые уже были найдены.</div>
        </div>}
      </Modal>
    </>
  );
}

function toggle(oldValue: boolean) {
  return !oldValue;
}

function sortWords(words: string[], lettersMap: Record<string, Set<number>>): string[] {
  return words.map(
    word => ({ word, score: getWordScore(word, lettersMap) })
  ).sort(
    ({ score: score1 }, { score: score2 }) => score2 - score1
  ).map(
    ({ word }) => word
  );
}

function getWordScore(word: string, lettersMap: Record<string, Set<number>>): number {
  return word.split('').reduce((acc, letter) => {
    addAll(acc, lettersMap[letter]);

    return acc;
  }, new Set<number>()).size;
}

function getLettersMap(words: string[]): Record<string, Set<number>> {
  const map: Record<string, Set<number>> = {};

  words.forEach((word, wordId) => {
    word.split('').forEach((letter) => {
      if (letter in map) {
        map[letter].add(wordId);
      } else {
        map[letter] = new Set<number>([wordId]);
      }
    })
  })

  return map;
}

type Filter = (word: string) => boolean;

function createFilter(previous: Letters[]): Filter {
  const filters = previous.flatMap(
    letters => letters.map(createLetterFilter)
  );

  return word => filters.every(filter => filter(word));
}

function createLetterFilter(letter: LetterType, index: number): Filter {
  switch (letter.type) {
    case 'wrong':
      return word => !word.includes(letter.letter);

    case 'correct':
      return word => word.includes(letter.letter) && word.charAt(index) !== letter.letter;

    case 'exact':
      return word => word.charAt(index) === letter.letter;

    default:
      return expectNever(letter.type);
  }
}

function getDefaultLetters(word: string = '?????', previous: Letters[]): Letters {
  const useMap = getUseMap(previous);

  return word.split('').map((letter, index) => ({ letter, type: getLetterUseType(useMap, letter, index) })) as Letters;
}

function getLetterUseType(useMap: Record<string, LetterUse>, letter: string, index: number): UseType {
  const letterUse = useMap[letter];

  if (letterUse === undefined || letterUse === 'wrong') {
    return 'wrong';
  }

  const found = letterUse.find(({ index: useIndex }) => useIndex === index);

  return found?.type ?? 'wrong';
}

function formatPercent(percent: number): number {
  if (percent >= 1) {
    return Math.round(percent);
  }

  if (percent >= 0.1) {
    return Math.round(percent * 10) / 10;
  }

  return Math.round(percent * 100) / 100;
}

function getNotFoundLetters(previous: Letters[]): string[] {
  return previous.flatMap(
    letters => letters.filter(({ type }) => type === 'wrong').map(({ letter }) => letter)
  );
}

function getFoundLetters(previous: Letters[]): string[] {
  return previous.flatMap(
    letters => letters.filter(({ type }) => type === 'correct' || type === 'exact').map(({ letter }) => letter)
  );
}

function addAll<T>(to: Set<T>, from?: Set<T>): void {
  if (!from) {
    return;
  }

  for (const value of from.values()) {
    to.add(value);
  }
}

type FoundLetter = {
  index: number;
  type: 'exact' | 'correct';
};

type LetterUse = FoundLetter[] | 'wrong';

const isFoundLetter = (letterUse: LetterUse): letterUse is FoundLetter[] => letterUse !== 'wrong';

function validate(letters: Letters, previous: Letters[]): number[] {
  const existingLetters = letters.filter(({ type }) => type === 'correct' || type === 'exact').map(({ letter }) => letter);

  const localConflicts = letters.map(({ letter, type }, index) => type === 'wrong' && existingLetters.includes(letter) ? index : undefined);

  // const useMap = getUseMap(previous);

  // const globalConflicts = letters.map(({ letter, type }, index) => {
  //   if (!(letter in useMap)) {
  //     return undefined;
  //   }

  //   const letterUse = useMap[letter];

  //   if (letterUse === 'wrong') {
  //     return type === 'wrong' ? undefined : index;
  //   }

  //   if (type === 'wrong') {
  //     return index;
  //   }

  //   return letterUse.some(({ index: useIndex, type: useType }) => useIndex === index && useType !== type) ? index : undefined;
  // });
  const validateGlobal = createCombinedValidator(previous);
  const globalConflicts = letters.map(({ letter, type }, index) => !validateGlobal(letter, type, index) ? index : undefined);

  return localConflicts.concat(globalConflicts).filter(isDefined);
}

function getUseMap(previous: Letters[]): Record<string, LetterUse> {
  return previous.reduce(
    (acc, letters) => letters.reduce<Record<string, LetterUse>>((acc, { letter, type }, index) => {
      if (type === 'wrong') {
        acc[letter] = 'wrong';
      } else {
        if (letter in acc) {
          const letterUse = acc[letter];
          if (!isFoundLetter(letterUse)) {
            throw new Error('wtf?');
          }

          letterUse.push({ index, type });
        } else {
          acc[letter] = [{ index, type }];
        }
      }

      return acc;
    }, acc)
    , {}
  )
}

type LetterValidator = (letter: string, type: UseType, index: number) => boolean;

function createCombinedValidator(previous: Letters[]): LetterValidator {
  const validators = previous.flatMap(
    letters => letters.map(
      ({ letter, type }, index) => createValidator(letter, type, index)
    )
  );

  return (letter, type, index) => validators.every(
    validate => validate(letter, type, index)
  );
}

function createValidator(validatorLetter: string, validatorType: UseType, validatorIndex: number): LetterValidator {
  switch (validatorType) {
    case 'wrong':
      return (letter, type) => letter !== validatorLetter || type === 'wrong';

    case 'correct':
      return (letter, type, index) => letter !== validatorLetter || (index !== validatorIndex && type !== 'wrong') || (index === validatorIndex && type === 'correct');

    case 'exact':
      return (letter, type, index) => index === validatorIndex && (letter === validatorLetter && type === 'exact' || letter !== validatorLetter && type !== 'exact') || index !== validatorIndex && (letter !== validatorLetter || type !== 'wrong');

    default:
      return expectNever(validatorType);
  }
}

function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}