import { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './Overlay.module.css';

type Props = {
    shown?: boolean;
    onClick?: VoidFunction;
    onHide?: VoidFunction;
}

export const Overlay = ({ shown, onClick, onHide }: Props) => {
    const [ isShown, setIsShown ] = useState(false);

    useEffect(() => {
        if (Boolean(shown) !== isShown) {
            setIsShown(Boolean(shown));
        }
    }, [shown, isShown])

    return <div className={classNames(styles.overlay, !isShown && styles.hidden)} onClick={onClick} onTransitionEnd={() => {
        if (!shown) {
            onHide?.();
        }
    }} />;
}