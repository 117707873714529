import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Button.module.css';

type Props = {
    children: ReactNode;
    primary?: boolean;
    rounded?: boolean;
    disabled?: boolean;
    grouped?: boolean;
    nowrap?: boolean;
    onClick?: VoidFunction;
}

export const Button = ({ children, primary, rounded, disabled, grouped, nowrap, onClick }: Props) => (
    <button className={classNames(styles.button, primary && styles.primary, rounded && styles.rounded, grouped && styles.grouped, nowrap && styles.nowrap)} disabled={disabled} onClick={onClick}>
        {children}
    </button>
);
